<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-15 16:49:30
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-22 18:11:03
 * @FilePath: /mediatom-web/src/viewsForManage/mediaDashboard/models/BoardList/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="media-dashboard-board-list">
    <m-ratio-box class="ratio-box" :ratio="63" v-for="(item, index) in boardList" :key="index">
      <div class="board-item" :style="{'font-size': fontSize + 'px'}">
        <div class="board-item-inner">
          <div class="coin-icon" :style="{ backgroundColor: `${item.color}30` }">
            <GoldCoin class="icon" :style="{ color: item.color }" />
          </div>
          <div class="value">
            <count-to :start-val="0" :decimals="2" :end-val="boardData[item.valueProp]" />
          </div>
          <div class="label">{{ item.label }}({{ moneyType }})</div>
          <div class="ratio">
            <div
              class="icon"
              v-if="item.ratioProp"
              :style="{ color: +boardData[item.ratioProp] <= 0 ? '#64C8BC' : '#FF8A8A' }"
            >
              <component
                class="ratio-icon"
                :is="+boardData[item.ratioProp] > 0 ? 'RightTop' : 'RightBottom'"
              ></component>
            </div>
            <div
              class="ratio-value"
              v-if="item.ratioProp"
              :style="{ color: +boardData[item.ratioProp] <= 0 ? '#64C8BC' : '#FF8A8A' }"
            >
              {{ boardData[item.ratioProp] || 0 }}%
            </div>
            <div class="no-ratio" v-else>
            </div>
          </div>
        </div>
      </div>
    </m-ratio-box>
  </div>
</template>

<script>
import { boardList } from './boardData'
import CountTo from 'vue-count-to'
import GoldCoin from '@/assets/icons/goldCoin.svg?inline'
import RightTop from '@/assets/icons/rightTop.svg?inline'
import RightBottom from '@/assets/icons/rightBottom.svg?inline'
import { mapState } from 'vuex'
import autoFontSize from '@/mixins/autoFontSize'
import { getMediaBoardData } from '@/apiForManage/mediaDashboard'
export default {
  components: { GoldCoin, CountTo, RightTop, RightBottom },
  mixins: [autoFontSize],
  data () {
    return {
      boardList,
      boardData: {}
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      adminUserId: (state) => state.user.adminUserId,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥')
    })
  },
  created () {
    this.getMediaBoardData()
  },
  methods: {
    async getMediaBoardData () {
      const { data = {} } = await getMediaBoardData()
      this.boardData = data
    }
  }
}
</script>
<style lang="less" scoped>
.media-dashboard-board-list {
  width: 100%;
  position: relative;
  border-radius: calc(10em / 14);
  display: flex;
  justify-content: space-between;
  .board-item {
    user-select: none;
    width: 100%;
    height: 100%;
    position: relative;
    .board-item-inner {
      height: 100%;
      border-radius: calc(10em / 14);
      background-color: #fff;
      box-shadow: 0 2px 6px 0 rgba(21, 34, 50, 0.08);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 2px 6px 0 rgba(21, 34, 50, 0.3);
      }
      .coin-icon {
        height: calc(36em / 14);
        width: calc(36em / 14);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          width: calc(28em / 14);
          height: calc(28em / 14);
        }
      }
      .value {
        margin-top: calc(14em / 14);
        font-size: 2em;
        color: #344563;
        line-height: 39px;
        font-weight: bold;
      }
      .label {
        font-size: calc(16em / 14);
        font-weight: 500;
        line-height: calc(22em / 14);
        margin-top: calc(6em / 14);
      }
      .ratio {
        height: calc(22em / 14);
        display: flex;
        justify-content: center;
        margin-top: calc(18em / 14);
        .ratio-icon {
          height: calc(22em / 14);
          width: calc(22em / 14);
        }
        .ratio-value {
          font-size: calc(16em / 14);
          font-weight: 500;
          line-height: calc(22em / 14);
          margin-left: calc(4em / 14);
        }
        .no-ratio{
          width: calc(30em / 14);
          height: calc(3em / 14);
          margin-top: calc(3em / 14);
          background-color: #f99600;
          border-radius: calc(3em / 14);
        }
      }
    }
  }
  .ratio-box {
    width: calc(25% - 21em / 14);
  }
}
</style>
