/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-18 18:03:27
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-18 18:19:02
 * @FilePath: /mediatom-web/src/viewsForManage/mediaDashboard/models/BoardList/boardData.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const boardList = [
  {
    valueProp: 'yesterdayRevenue',
    ratioProp: 'yesterdayRatio',
    color: '#61A4E4',
    label: '昨日收益'
  },
  {
    valueProp: 'sevenDaysRevenue',
    ratioProp: 'sevenDaysRatio',
    color: '#6554C0',
    label: '七日收益'
  },
  {
    valueProp: 'monthRevenue',
    ratioProp: 'monthRatio',
    color: '#FFA958',
    label: '本月收益'
  },
  {
    valueProp: 'sumRevenue',
    color: '#45B854',
    label: '总收益'
  }
]
